const DSLPolicyDatas = {
  title: "Politique de Confidentialité",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Chez ${process.env.REACT_APP_COMPANY_NAME || `Nom de l'entreprise`}, nous nous engageons à protéger la confidentialité de vos informations personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site web et notre formulaire de contact.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. Collecte des informations",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `${process.env.REACT_APP_APP_NAME || `Nom de l'application`} ne collecte pas de données personnelles sensibles telles que votre nom, adresse e-mail, numéro de téléphone ou toute autre information qui vous identifie directement.`
              }
            ]
          },
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `L'application utilise ${process.env.REACT_APP_STORAGE_LIBRARY || '@react-native-async-storage/async-storage'} pour stocker localement des informations liées à l'utilisation de l'application. Ces données ne sont pas transmises à des serveurs externes ou à des tiers et peuvent inclure vos préférences utilisateur ou d'autres informations non personnelles.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "2. Utilisation des données",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: "Les données stockées localement sont utilisées exclusivement pour améliorer votre expérience utilisateur. Par exemple, elles peuvent être utilisées pour : Sauvegarder vos préférences dans l'application, Améliorer la fonctionnalité et les performances de l'application."
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "3. Publicité",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `${process.env.REACT_APP_APP_NAME || `Nom de l'application`} utilise ${process.env.REACT_APP_AD_PROVIDER || 'Google Mobile Ads'} pour diffuser des publicités non personnalisées. Cela signifie qu'aucune donnée personnelle n'est utilisée pour cibler les publicités que vous voyez. L'option '${process.env.REACT_APP_AD_REQUEST_OPTION || 'requestNonPersonalizedAdsOnly'}' est activée pour garantir que seules des publicités génériques sont affichées.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "4. Partage d'informations avec des tiers",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: "Nous ne partageons aucune donnée personnelle ou non personnelle avec des tiers. Comme mentionné précédemment, toutes les données restent stockées localement sur votre appareil et ne sont pas envoyées à des serveurs externes."
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "5. Sécurité des données",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: "Nous prenons des mesures raisonnables pour protéger les données stockées sur votre appareil contre la perte, l'utilisation abusive ou l'accès non autorisé. Cependant, nous ne pouvons garantir une sécurité absolue en raison des risques inhérents à l'utilisation d'Internet et des technologies mobiles."
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "6. Services externes et bibliothèques utilisés",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `${process.env.REACT_APP_APP_NAME || `Nom de l'application`} utilise plusieurs bibliothèques tierces, dont certaines peuvent avoir leurs propres politiques de confidentialité. Les bibliothèques et services utilisés par l'application incluent : ${process.env.REACT_APP_USED_LIBRARIES || '@expo/vector-icons, @react-native-async-storage/async-storage, @react-navigation/native'}. Ces bibliothèques sont soumises à leurs propres conditions d'utilisation et politiques de confidentialité. Nous vous encourageons à consulter ces politiques pour plus d'informations sur l'utilisation éventuelle de vos données par ces services.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "7. Modifications de la politique de confidentialité",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: "Cette politique de confidentialité peut être mise à jour de temps à autre. En cas de modification substantielle, nous vous en informerons via l'application ou par d'autres moyens avant que les modifications ne prennent effet. Nous vous encourageons à consulter cette page régulièrement pour rester informé de nos pratiques en matière de confidentialité."
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "8. Vos droits",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `Étant donné que l'application ne collecte ni ne traite de données personnelles, vous n'avez pas besoin d'exercer de droits spécifiques liés à la vie privée. Cependant, si vous avez des questions ou des préoccupations concernant la confidentialité ou l'utilisation des données dans l'application, vous pouvez nous contacter à l'adresse suivante : ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@exemple.com'}.`
              }
            ]
          }
        ]
      }
    },
    {
      type: "section",
      attributes: {
        title: "9. Contact",
        content: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: `Si vous avez des questions concernant cette politique de confidentialité ou l'utilisation de ${process.env.REACT_APP_APP_NAME || `Nom de l'application`}, vous pouvez nous contacter par e-mail à l'adresse suivante : ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@exemple.com'}.`
              }
            ]
          }
        ]
      }
    }
  ]
};

export default DSLPolicyDatas;