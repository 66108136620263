const privacyPolicy = {
  title: "Politique de Confidentialité",
  introduction: `Chez ${process.env.COMPANY_NAME || 'Nom de l\'entreprise'}, nous nous engageons à protéger la confidentialité de vos informations personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site web et notre formulaire de contact.`,
  sections: [
    {
      title: "1. Collecte des informations",
      content: `Lorsque vous utilisez notre formulaire de contact, nous collectons les informations suivantes :
      - Votre adresse e-mail
      - Le sujet de votre message
      - Le contenu de votre message

      Ces informations sont nécessaires pour que nous puissions répondre à vos demandes ou vous contacter si nécessaire.`
    },
    {
      title: "2. Utilisation des informations",
      content: `Les informations collectées via notre formulaire de contact sont utilisées uniquement à des fins de communication. Plus précisément :
      - Pour répondre à vos demandes envoyées via le formulaire de contact.
      - Pour vous contacter au sujet de vos messages.

      Nous utilisons le service Email.js pour envoyer vos messages vers notre adresse email (${process.env.CONTACT_EMAIL || 'tonemail@example.com'}). Ce service agit comme un intermédiaire pour transférer vos informations sans stocker vos données à long terme.`
    },
    {
      title: "3. Partage des informations",
      content: "Vos informations personnelles ne sont pas partagées avec des tiers, sauf dans les cas où la loi l'exige. Nous ne vendons, n'échangeons, ni ne louons vos informations personnelles à des tiers."
    },
    {
      title: "4. Sécurité des informations",
      content: "Nous prenons des mesures raisonnables pour protéger vos informations personnelles contre l'accès non autorisé ou la divulgation. Toutefois, veuillez noter qu'aucune méthode de transmission de données via Internet ou de stockage électronique n'est complètement sécurisée."
    },
    {
      title: "5. Droits de l'utilisateur",
      content: `Conformément au Règlement Général sur la Protection des Données (RGPD), vous avez le droit de :
      - Accéder aux informations personnelles que nous détenons à votre sujet.
      - Demander la rectification de toute information incorrecte.
      - Demander la suppression de vos informations personnelles.

      Pour exercer ces droits, vous pouvez nous contacter directement à ${process.env.CONTACT_EMAIL || 'tonemail@example.com'}.`
    },
    {
      title: "6. Cookies",
      content: "Notre site n'utilise pas de cookies pour collecter des informations personnelles."
    },
    {
      title: "7. Modifications de la politique de confidentialité",
      content: "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page et prendra effet immédiatement."
    },
    {
      title: "8. Contact",
      content: `Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à : ${process.env.REACT_APP_CONTACT_EMAIL || 'tonemail@example.com'}.`
    }
  ],
  footer: {
    text: `&copy; ${new Date().getFullYear()} ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'entreprise'}. Tous droits réservés.`,
    link: {
      text: "Retour à l'accueil",
      href: "/"
    }
  }
};

export default privacyPolicy;