const versionResume = "1.0" // to update Resume change version and name it : Resume_v1.0.png and same for pdf

const contact = {
  "data": {
    "id": 1,
    "attributes": {
      "createdAt": "2024-05-20T14:31:42.682Z",
      "updatedAt": "2024-07-22T14:52:35.583Z",
      "publishedAt": "2024-05-20T14:37:28.395Z",
      "locale": "fr",
      "formResponse": "Votre message a bien été envoyé",
      "title": {
        "id": 1,
        "MainTitle": "Contactez-moi",
        "subtitle": "Remplissez le formulaire ci-dessous pour me contacter"
      },
      "Resume": {
        "data": {
          "id": 74,
          "attributes": {
            "name": "CV_20_07_2024.pdf",
            "alternativeText": null,
            "caption": null,
            "width": null,
            "height": null,
            "formats": null,
            "hash": "CV_20_07_2024_eca5ea5262",
            "ext": ".pdf",
            "mime": "application/pdf",
            "size": 110.35,
            "url": `./uploads/Resume_v${versionResume}.pdf`,
            "previewUrl": null,
            "provider": "local",
            "provider_metadata": null,
            "createdAt": "2024-07-20T13:20:15.023Z",
            "updatedAt": "2024-07-20T13:20:15.023Z"
          }
        }
      },
      "pictureResume": {
        "data": {
          "id": 71,
          "attributes": {
            "name": "Resume_JavaScript_dev_Giovanni_Zoppis.png",
            "alternativeText": "Resume JavaScript dev Giovanni Zoppis",
            "caption": null,
            "width": 1414,
            "height": 2000,
            "formats": {
              "thumbnail": {
                "name": "thumbnail_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "thumbnail_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 110,
                "height": 156,
                "size": 18.58,
                "sizeInBytes": 18583,
                "url": "/uploads/thumbnail_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              },
              "medium": {
                "name": "medium_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "medium_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 530,
                "height": 750,
                "size": 200.58,
                "sizeInBytes": 200576,
                "url": "/uploads/medium_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              },
              "large": {
                "name": "large_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "large_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 707,
                "height": 1000,
                "size": 284.4,
                "sizeInBytes": 284397,
                "url": "/uploads/large_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              },
              "small": {
                "name": "small_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "small_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 354,
                "height": 500,
                "size": 110.28,
                "sizeInBytes": 110281,
                "url": "/uploads/small_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              }
            },
            "hash": "Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
            "ext": ".png",
            "mime": "image/png",
            "size": 123.36,
            "url": `/uploads/Resume_v${versionResume}.png`,
            "previewUrl": null,
            "provider": "local",
            "provider_metadata": null,
            "createdAt": "2024-05-21T15:04:33.773Z",
            "updatedAt": "2024-07-20T13:21:58.925Z"
          }
        }
      },
      "messageCtaText": {
        "id": 3,
        "text": "Envoyer"
      },
      "resumeCtaText": {
        "id": 4,
        "text": "Télécharger le CV"
      },
      "labelMail": {
        "id": 5,
        "text": "Votre email"
      },
      "labelSubject": {
        "id": 6,
        "text": "Sujet"
      },
      "labelMessage": {
        "id": 7,
        "text": "Message"
      },
      "messageSent": {
        "id": 8,
        "text": "Message envoyé!"
      },
      "messageError": {
        "id": 9,
        "text": "Une erreur s'est produite. Veuillez essayer plus tard."
      },
      "requiredMail": {
        "id": 10,
        "text": "L'email est requis."
      },
      "requiredSubject": {
        "id": 11,
        "text": "Le sujet est requis."
      },
      "requiredMessage": {
        "id": 12,
        "text": "Le message ne peut pas être vide."
      },
      "invalidMail": {
        "id": 13,
        "text": "L'adresse email est invalide."
      },
      "messageErrorSubmit": {
        "id": 14,
        "text": "Une erreur s'est produite. Veuillez essayer plus tard."
      }
    }
  },
  "meta": {}
}

export default contact