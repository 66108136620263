import { useDatasContext } from '../../context/DatasProvider.js';
import AboutMe from '../../components/AboutMe';
import Title from '../../components/Title/index.jsx';
import Footer from '../../components/Footer/index.jsx';
import './index.scss';
import CanvasThree from '../../components/CanvasThree.jsx';
import { memo } from 'react';
const About = () => {
    const { datas, isLoading, error } = useDatasContext();
    const title = datas?.about?.attributes?.title;

    if (!datas.about) return <CanvasThree
        location={'/'}
        loading={isLoading.about ?? true}
        error={error.about ?? undefined}
    />;

    return (
        <>
            <CanvasThree
                location={'/about'}
                loading={isLoading.about ?? true}
                error={error.about ?? undefined}
            />
            <main className='about-container'>
                {title &&
                    <Title datas={title} />}
                {isLoading.about ? <h1>Loading...</h1> : error.about ? <h1>{error.about}</h1> :
                    <AboutMe datas={datas.about} loading={isLoading.about} error={error.about} />}
            </main >
            {isLoading.about || error.about ? null : <Footer />}
        </>
    );
}

export default memo(About);

