const footer = {
  "data": {
    "id": 1,
    "attributes": {
      "createdAt": "2024-05-23T14:15:23.524Z",
      "updatedAt": "2024-07-22T10:05:26.087Z",
      "publishedAt": "2024-05-23T14:16:10.227Z",
      "locale": "fr",
      "description": "Passionné par le développement Fullstack, je conçois avec enthousiasme des applications web et mobiles !",
      "socialIcon": [
        {
          "id": 1,
          "url": "https://github.com/Gi0vak",
          "icon": "github"
        },
        {
          "id": 2,
          "url": "https://www.linkedin.com/in/giovanni-zoppis/",
          "icon": "linkedin"
        }
      ],
      "text": `&copy; ${new Date().getFullYear()
        } ${process.env.COMPANY_NAME || 'Nom de l\'entreprise'
        }. Tous droits réservés.`,
      "link": [
        {
          "text": "Politique de confidentialité",
          "href": "/policy"
        },
        {
          "text": "Mentions légales",
          "href": "/notice"
        }]
    },
    "meta": {}
  }
}

export default footer;