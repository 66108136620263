
import './index.scss';
import { iconMapping } from '../../../utils/icons';
import { memo } from "react";


const SocialLinks = ({ socialDatas }) => {

  if (!socialDatas) return null

  return (
    <div className="footer-section links">
      <h3>SOCIAL</h3>
      <ul className="social-icons">
        {socialDatas.map(link => {
          const IconComponent = iconMapping[link.icon].icon;
          const colorIcon = iconMapping[link.icon].color;
          return (
            <a
              key={link.id}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer">
              {IconComponent && <IconComponent color={colorIcon} size={30} />}
            </a>
          );
        })}
      </ul>
    </div>
  )
};


export default memo(SocialLinks);