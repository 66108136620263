import { memo, useEffect } from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import './index.scss';
import SkillTag from './SkillTag';

const AboutMe = ({ datas, loading, error }) => {
  // const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const titleaboutme = datas?.attributes?.titleaboutme;
  const aboutme = datas?.attributes?.aboutme;
  const skilltags = datas?.attributes?.skillTag;
  const aboutCtaText = datas?.attributes?.aboutCtaText;
  // const urlProfilePicture = baseUrl + datas?.attributes?.profilepicture?.data?.attributes?.url || null;
  // const altProfilePicture = datas?.attributes?.profilepicture?.data?.attributes?.alternativeText || null;
  useEffect(() => {
    const timeline = gsap.timeline({ paused: true });
    if (!loading && !error) {
      timeline.fromTo(".about-subtitle, .skill-subtitle", {
        opacity: 0,
        y: 70
      }, {
        delay: 0.3,
        duration: 1.3,
        opacity: 1,
        y: 0,
        ease: "power1.out"
      })

      timeline.fromTo(".about-text>p, .tags-container, .about-button", {
        opacity: 0,
        y: 70
      }, {
        duration: 1.3,
        opacity: 1,
        y: 0,
        ease: "power1.out"
      }, "-=0.3")


      timeline.eventCallback("onComplete", () => {
        timeline.clear();
        gsap.set([".about-subtitle", ".about-text>p", ".about-button", ".skill-subtitle", ".tags-container"], {
          clearProps: "all"
        });
      });

      timeline.play();
    }

  }, [loading, error]);


  if ((loading || error) || !aboutCtaText || !titleaboutme || !aboutme || !skilltags) return null

  return (
    <section className='about-section'>
      <article className='about-article'>
        <h3 className='about-subtitle'>{titleaboutme}</h3>
        <div className='about-text'>
          {<BlocksRenderer content={aboutme} />}
        </div>
        <Link to='/contact' className='about-button'>
          <p>{aboutCtaText.text}</p>
        </Link>
      </article>

      <aside className='about-skills'>
        <h3 className='skill-subtitle'>Mes compétences</h3>
        <div className='tags-container'>
          {skilltags.map((skill, index) => {
            return <SkillTag key={index} skillName={skill.name} />;
          })}
        </div>
      </aside>
    </section>

  )
}

export default memo(AboutMe);
