
import './index.scss';
import { iconMapping } from '../../../utils/icons';
import { memo } from "react";


const SkillTag = ({ skillName }) => {

  const IconComponent = iconMapping[skillName].icon;
  const colorIcon = iconMapping[skillName].color;

  if (!IconComponent || !skillName) return null

  return (
    <div className="tag-container">
      {IconComponent && <IconComponent color={colorIcon} size={55} />}
      <p>{skillName}</p>
    </div>
  )
};


export default memo(SkillTag);