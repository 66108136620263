import React, { memo } from 'react'
import './index.scss'

const Spinner = () => {
  return (
    <div className="simple-spinner">
      <span></span>
    </div>
  )
}

export default memo(Spinner);
