const legalMentions = {
  title: "Mentions Légales",
  blocks: [
    {
      type: "section", // Définir le type du bloc
      title: "1. Éditeur du site",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Le site ${process.env.REACT_APP_WEBSITE_URL || 'Nom du site'} est édité par :`
            },
            {
              type: "text",
              text: `Nom : ${process.env.REACT_APP_COMPANY_NAME || 'Nom de l’entreprise'}`
            },
            {
              type: "text",
              text: `Statut : ${process.env.REACT_APP_COMPANY_STATUS || 'Micro-entrepreneur'}`
            },
            {
              type: "text",
              text: `Adresse : ${process.env.REACT_APP_COMPANY_ADDRESS || 'Ton adresse professionnelle ou personnelle'}`
            },
            {
              type: "text",
              text: `SIRET : ${process.env.REACT_APP_COMPANY_SIRET || 'Numéro de SIRET'}`
            },
            {
              type: "text",
              text: `Numéro de TVA : ${process.env.REACT_APP_COMPANY_VAT || 'Numéro de TVA si applicable'}`
            },
            {
              type: "text",
              text: `Email de contact : ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@tonsite.com'}`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      title: "2. Hébergement du site",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Le site est hébergé par :`
            },
            {
              type: "text",
              text: `Hébergeur : ${process.env.REACT_APP_HOSTING_PROVIDER || 'Vercel Inc.'}`
            },
            {
              type: "text",
              text: `Adresse : ${process.env.REACT_APP_HOSTING_ADDRESS || '340 S Lemon Ave #4133, Walnut, CA 91789'}`
            },
            {
              type: "text",
              text: `Site Web : ${process.env.REACT_APP_HOSTING_WEBSITE || 'https://vercel.com'}`
            },
            {
              type: "text",
              text: `Contact : ${process.env.REACT_APP_HOSTING_CONTACT || 'support@vercel.com'}`
            }
          ]
        }
      ]
    },
    // Continue similarly for other sections...
  ]
};

export default legalMentions;