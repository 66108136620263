const DSLPolicyDatasEN = {
  title: "Privacy Policy",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `At ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'}, we respect your privacy and are committed to protecting the personal information you provide when using our ${process.env.REACT_APP_APP_NAME || 'Application'}. This privacy policy explains how we collect, use, and protect your information when you use our app.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. Information Collection",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_APP_NAME || 'Application'} does not collect sensitive personal data such as your name, email address, phone number, or any other information that directly identifies you.`
            }
          ]
        },
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `The app uses ${process.env.REACT_APP_STORAGE_LIBRARY || '@react-native-async-storage/async-storage'} to store information locally on your device. This data is not transmitted to external servers or third parties and may include your preferences or other non-personal information.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "2. Data Usage",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "The locally stored data is used exclusively to improve your user experience. For example, it may be used to save your app preferences or enhance the app's functionality and performance."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "3. Advertising",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_APP_NAME || 'Application'} uses ${process.env.REACT_APP_AD_PROVIDER || 'Google Mobile Ads'} to display non-personalized ads. This means no personal data is used to target the ads you see. The 'requestNonPersonalizedAdsOnly' option is enabled to ensure only generic ads are shown. No user information is collected or shared in connection with these ads.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "4. Information Sharing with Third Parties",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "We do not share any personal or non-personal data with third parties. As mentioned earlier, all data remains stored locally on your device and is not transmitted to external servers."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "5. Data Security",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "We take reasonable measures to protect the data stored on your device from loss, misuse, or unauthorized access. However, we cannot guarantee absolute security due to the inherent risks of using the Internet and mobile technologies."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "6. External Services and Libraries Used",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_APP_NAME || 'Application'} uses several third-party libraries, some of which may have their own privacy policies. The libraries and services used by the app include: ${process.env.REACT_APP_USED_LIBRARIES || '@expo/vector-icons, @react-native-async-storage/async-storage, @react-navigation/native, dotenv, expo, expo-file-system, expo-font, expo-localization, expo-router, expo-sharing, expo-splash-screen, expo-status-bar, expo-system-ui, expo-updates, i18n-js, react, react-native, react-native-google-mobile-ads, react-native-reanimated, react-native-safe-area-context, react-native-screens'}. These libraries are subject to their own terms of use and privacy policies. We encourage you to review these policies to understand how your data might be used by these services.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "7. Changes to the Privacy Policy",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: "This privacy policy may be updated from time to time. In the event of a significant change, we will notify you through the app or by other means before the changes take effect. We encourage you to review this page regularly to stay informed about our privacy practices."
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "8. Your Rights",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Since the application does not collect or process personal data, you do not need to exercise specific privacy rights. However, if you have any questions or concerns regarding privacy or data usage within the app, you can contact us at: ${process.env.REACT_APP_CONTACT_EMAIL || 'your-email@example.com'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "9. Contact",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
            }
          ]
        }
      ]
    }
  ]
};

export default DSLPolicyDatasEN;