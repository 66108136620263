import React, { useState, useEffect, useMemo, useRef, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import './index.scss';

const Navbar = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [menuOpen, setMenuOpen] = useState(false);
    const tl = useRef(gsap.timeline({ paused: true }));
    const menuRef = useRef(null);

    const classNameNav = useMemo(() => ([
        activeLink === '/' ? 'nav-link active' : 'nav-link',
        activeLink === '/about' ? 'nav-link active' : 'nav-link',
        activeLink === '/contact' ? 'nav-link active' : 'nav-link',
    ]), [activeLink]);

    const classNameBurgerNav = useMemo(() => ([
        activeLink === '/' ? 'nav-link-ham active-ham' : 'nav-link-ham',
        activeLink === '/about' ? 'nav-link-ham active-ham' : 'nav-link-ham',
        activeLink === '/contact' ? 'nav-link-ham active-ham' : 'nav-link-ham',
    ]), [activeLink]);

    const handleClick = (link) => {
        setActiveLink(link);
        setMenuOpen(false);
    };

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);


    useEffect(() => {
        if (window.innerWidth < 768) {
            tl.current.to('.ham-menu', {
                duration: 0.5,
                opacity: 1,
                height: '70vh',
                ease: 'power1.out',
                immediateRender: false
            });

            tl.current.reverse({
                ease: 'power1.in',
                duration: 1,
            });
        }
    }, [tl]);

    useEffect(() => {
        if (menuOpen) {
            tl.current.play();
        } else {
            tl.current.reverse();
        }
    }, [menuOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <nav>
            <div className='logo-container'>
                <Link to="/" onClick={() => { window.scrollTo(0, 0) }} >
                    <img className='logo' loading='lazy' src="./uploads/logo.png" alt="logo" style={{ width: "3.5rem", aspectRatio: "1/1", objectFit: "contain", marginRight: "1.2rem", paddingTop: ".8rem" }} />
                    <h1 className='logo-name'>
                        GZReactives
                    </h1>
                </Link>
            </div>

            <div className="wrapper" ref={menuRef}>
                <div className="ham">
                    <span onClick={() => setMenuOpen(!menuOpen)}>☰</span>
                </div>
                <ul className="ham-menu">
                    <li>
                        <Link to="/" className={classNameBurgerNav[0]}
                            onClick={() => handleClick('/')}>
                            Portefolio
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" className={classNameBurgerNav[1]}
                            onClick={() => handleClick('/about')}>
                            À propos
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" className={classNameBurgerNav[2]}
                            onClick={() => handleClick('/contact')}>
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
            <ul className="nav-links">
                <li>
                    <Link to="/" className={classNameNav[0]}
                        onClick={() => handleClick('/')}>
                        Portefolio
                    </Link>
                </li>
                <li>
                    <Link to="/about" className={classNameNav[1]}
                        onClick={() => handleClick('/about')}>
                        À propos
                    </Link>
                </li>
                <li>
                    <Link to="/contact" className={classNameNav[2]}
                        onClick={() => handleClick('/contact')}>
                        Contact
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default memo(Navbar);