const DSLNotice = {
  title: "Conditions d'Utilisation",
  blocks: [
    {
      type: "introduction",
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Conditions d'utilisation de l'application ${process.env.REACT_APP_APP_NAME || 'Nom de l\'application'}, mises à jour le ${process.env.REACT_APP_LAST_UPDATE || 'Date'}.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "1. Acceptation des Conditions",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `En utilisant l'application ${process.env.REACT_APP_APP_NAME || 'Nom de l\'application'}, vous acceptez ces conditions d'utilisation dans leur intégralité. Si vous n'acceptez pas ces conditions, vous ne devez pas utiliser l'application.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "2. Utilisation de l'Application",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `L'application ${process.env.REACT_APP_APP_NAME || 'Nom de l\'application'} vous permet de gérer une liste de courses. Vous acceptez d'utiliser l'application à des fins personnelles et non commerciales uniquement. Toute tentative de décompiler, désassembler ou rétroconcevoir l'application est strictement interdite.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "3. Responsabilité de l'Utilisateur",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Vous êtes responsable de protéger vos informations personnelles et de sécuriser l'accès à votre appareil. ${process.env.REACT_APP_COMPANY_NAME || 'L\'éditeur'} ne saurait être tenu responsable de toute perte de données résultant d'une mauvaise utilisation de l'application.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "4. Modification des Conditions",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `${process.env.REACT_APP_COMPANY_NAME || 'Nom de l\'éditeur'} se réserve le droit de modifier ces conditions d'utilisation à tout moment. Les modifications prendront effet immédiatement après leur publication dans l'application. Il est de votre responsabilité de consulter régulièrement les conditions d'utilisation.`
            }
          ]
        }
      ]
    },
    {
      type: "section",
      attributes: {
        title: "5. Droit Applicable",
      },
      content: [
        {
          type: "paragraph",
          children: [
            {
              type: "text",
              text: `Ces conditions d'utilisation sont régies par les lois de ${process.env.REACT_APP_COUNTRY || 'Pays'}. Tout litige lié à l'utilisation de l'application sera soumis à la juridiction exclusive des tribunaux compétents de ${process.env.REACT_APP_CITY || 'Ville'}/${process.env.REACT_APP_COUNTRY || 'Pays'}.`
            }
          ]
        }
      ]
    }
  ]
};

export default DSLNotice;