import { useEffect } from 'react';
function useHandleScroll(ref) {
  useEffect(() => {
    const handleScroll = () => {
      const cardTop = ref.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Si la position de la carte est dans la fenêtre visible
      if (cardTop < windowHeight * 0.8) {
        // Ajouter une classe pour afficher la carte
        ref.current.classList.add('show');
      }
    };

    // Ajouter un écouteur d'événement de défilement
    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);
}

export default useHandleScroll;
