import { memo, useMemo } from 'react';
import { useDatasContext } from '../../context/DatasProvider';
import ContactForm from '../../components/ContactForm'
import Resume from '../../components/Resume'
import Footer from '../../components/Footer'
import Title from '../../components/Title'
import CanvasThree from '../../components/CanvasThree';
import './index.scss'
const Contact = () => {
    const { datas, isLoading, error } = useDatasContext();
    const urlResume = datas?.contact?.attributes?.Resume?.data?.attributes?.url ?? undefined;
    const urlPictureResume = datas?.contact?.attributes?.pictureResume?.data?.attributes?.url ?? undefined;
    const urlalternativeTextPictureResume = datas?.contact?.attributes?.pictureResume?.data?.attributes?.alternativeText ?? undefined;
    const datasContact = useMemo(() => datas?.contact, [datas]);
    const title = datasContact?.attributes?.title

    if (!datasContact) return <CanvasThree
        location={'/contact'}
        loading={isLoading.contact ?? true}
        error={error.contact ?? undefined}
    />;
    return (
        <main className='contact-main'>
            <CanvasThree
                location={'/contact'}
                loading={isLoading.contact ?? true}
                error={error.contact ?? undefined}
            />
            {isLoading.contact ?
                <h1>Loading...</h1> :
                error.contact ? <h1>{error.contact}</h1> :
                    <>
                        {title && <Title datas={title} />}
                        <section className='contact-section'>
                            <ContactForm datas={datasContact} />
                            {(urlResume &&
                                urlPictureResume &&
                                urlalternativeTextPictureResume) &&
                                <Resume
                                    datas={datasContact}
                                    pdfUrl={urlResume}
                                    pngUrl={urlPictureResume}
                                    altImage={urlalternativeTextPictureResume}
                                />}
                        </section>
                    </>
            }
            {isLoading.contact || error.contact ? null : <Footer />}
        </main>
    )
}
export default memo(Contact);