import { memo, useState } from 'react';
import validateForm from '../../utils/validateForm';
import sendMail from '../../utils/sendMail';
import Spinner from '../../components/Spinner';
import './index.scss'

const ContactForm = ({ datas }) => {
  const labelMail = datas && datas.attributes.labelMail.text;
  const labelSubject = datas && datas.attributes.labelSubject.text;
  const labelMessage = datas && datas.attributes.labelMessage.text;
  const ctaSend = datas && datas.attributes.messageCtaText.text;
  const messageSent = datas && datas.attributes.messageSent.text;

  const [inputs, setInputs] = useState({
    mail: '',
    subject: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formErrors = validateForm(inputs, datas);
    console.log("formErrors", formErrors);
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    } else {
      setErrors(formErrors);
      !send && sendMail({ errorMessage: datas?.attributes?.messageErrorSubmit?.text, setSend: setSend, setLoading: setLoading, setErrors: setErrors, setInputs: setInputs, inputs: inputs });
    }

  }

  return (
    <div className="contact-form-container">
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="mail">{labelMail}</label>
          <input
            placeholder="Entrez votre adresse email"
            id="mail"
            name={"mail"}
            value={inputs.mail}
            onChange={handleChange}
          />

          {errors.mail && <div>
            <p className="error">
              {errors.mail}
            </p>
          </div>}

        </div>

        <div className="input-group">
          <label htmlFor="subject">{labelSubject}</label>
          <input
            placeholder="Entrez l'objet de votre message"
            id="subject"
            name={"subject"}
            value={inputs.subject}
            onChange={handleChange}
          />

          {errors.subject && <div>
            <p className="error">
              {errors.subject}
            </p>
          </div>}

        </div>

        <div className="input-group">
          <label htmlFor="message">{labelMessage}</label>
          <textarea
            placeholder="Entrez votre message"
            id="message"
            name={"message"}
            value={inputs.message}
            onChange={handleChange}
          >
          </textarea>

          {errors.message && <div>
            <p className="error">
              {errors.message}
            </p>
          </div>}
        </div>

        {loading ?
          <button disabled className="loader-button">
            <Spinner />
          </button>
          : send ?
            <span className="send-message">
              <button disabled className="submit-button">
                <p>{ctaSend}</p>
              </button>
              <p>{messageSent}</p>
            </span>
            :
            <button type="submit" className="submit-button">
              <p>{ctaSend}</p>
            </button>
        }

        {errors.submit && <div>
          <p className="error">
            {errors.submit}
          </p>
        </div>}

      </form>
    </div>
  );
}

export default memo(ContactForm)
