import { useDatasContext } from '../../context/DatasProvider.js';
import Title from '../../components/Title/index.jsx';
import AllCardsProjects from '../../components/AllCardsProjects/index.jsx';
import CanvasThree from '../../components/CanvasThree.jsx';
import './index.scss';
import { memo } from 'react';
import Footer from '../../components/Footer/index.jsx';

const App = () => {
    const { datas, isLoading, error } = useDatasContext();
    const datasTitle = datas?.portfolio?.attributes?.title ?? undefined;



    if (!datas?.portfolio) return <CanvasThree
        location={'/'}
        loading={isLoading.portfolio ?? true}
        error={error.portfolio ?? undefined}
    />;

    return (
        <>
            <CanvasThree
                location={'/'}
                loading={isLoading.portfolio}
                error={error.portfolio}
            />
            <div className="projects-main-container">
                {isLoading.portfolio ? (
                    <h1>Loading...</h1>
                ) : error.portfolio ? (
                    <h1>{error.portfolio}</h1>
                ) : (
                    <>
                        <div className='title-arrows-container'>
                            {datas?.portfolio?.attributes?.title && <Title datas={datasTitle} />}
                            <div className='arrows-container'>
                                <div className="arrow arrow-first"></div>
                                <div className="arrow arrow-second"></div>
                            </div>
                        </div>
                        <AllCardsProjects />
                    </>
                )}
            </div>
            {isLoading.portfolio || error.portfolio ? null : <Footer />}
        </>
    );
};


export default memo(App);
