const footerEN = {
  "data": {
    "id": 2,
    "attributes": {
      "createdAt": "2024-07-05T07:24:19.425Z",
      "updatedAt": "2024-07-22T10:06:19.952Z",
      "publishedAt": "2024-07-20T13:24:58.749Z",
      "locale": "en",
      "description": "Passionate about Fullstack development, I enthusiastically design web and mobile applications!",
      "text": `&copy; ${new Date().getFullYear()} ${process.env.COMPANY_NAME || 'Giovanni Zoppis'}. All rights reserved.`,
      "socialIcon": [
        {
          "id": 58,
          "url": "https://github.com/Gi0vak",
          "icon": "github"
        },
        {
          "id": 59,
          "url": "https://www.linkedin.com/in/giovanni-zoppis/",
          "icon": "linkedin"
        }
      ],
      "link": [
        {
          "text": "Privacy Policy",
          "href": "/policy"
        },
        {
          "text": "Notice",
          "href": "/notice"
        }
      ],
    }
  },
  "meta": {}
}

export default footerEN;