import { memo } from 'react';
import CardProject from '../CardProject';
import { useDatasContext } from '../../context/DatasProvider.js';

import './index.scss'

const AllCardsProjects = () => {
  const { datas, isLoading, error } = useDatasContext();

  if (!datas.projects) return null;

  return (
    <div className="projects-cards-container">
      {isLoading.project ? <h1>Loading...</h1>
        : error.project ? <h1>{error.project}</h1>
          : (
            datas.projects.map((el, i) => {
              return <CardProject datas={el} key={i} />
            }))}
    </div>
  )
}

export default memo(AllCardsProjects);
