import { memo } from "react";
import { useDatasContext } from "../../context/DatasProvider";
import SocialLinks from "./SocialIcons";
import './index.scss';
import { Link } from "react-router-dom";
const Footer = () => {
  const { datas, isLoading, error } = useDatasContext();
  const socialDatas = datas?.footer?.attributes?.socialIcon;
  const descriptionFooter = datas?.footer?.attributes?.description;
  const legalLinks = datas?.footer?.attributes?.link;

  if (!datas.footer) return null;

  return (
    <footer className="footer">
      {isLoading.footer ? <h1>Loading...</h1> : error.footer ? <h1>{error.footer}</h1> : (<>
        <div className="footer-content">
          <div className="footer-section">
            <h1>{process.env.REACT_APP_COMPANY_NAME}</h1>
            <p>{socialDatas && descriptionFooter}</p>
          </div>
          {socialDatas && <SocialLinks socialDatas={socialDatas} />}
        </div>
        <div className="footer-bottom">
          <p>© Copyright 2024. Made by Giovanni Zoppis</p>
          <div className="legal-links">
            {legalLinks?.map((link, index) => (
              <Link style={{ display: "flex", flexDirection: "row", alignItems: "center" }} key={index} to={link.href} onClick={() => { window.scrollTo(0, 0) }}>
                <p className="legal-link">{link.text}</p>
                {index % 2 === 0 && <span className="separator">|</span>}
              </Link>
            ))}
          </div>
        </div>
      </>
      )}
    </footer>
  )
}

export default memo(Footer);
