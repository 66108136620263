const versionResume = "1.0" // to update Resume change version and name it : Resume_v1.0.png and same for pdf

const contactEN = {
  "data": {
    "id": 2,
    "attributes": {
      "createdAt": "2024-05-20T14:31:55.291Z",
      "updatedAt": "2024-07-22T14:52:35.606Z",
      "publishedAt": "2024-06-19T13:55:54.792Z",
      "locale": "en",
      "formResponse": "Your message has been sent correctly",
      "title": {
        "id": 2,
        "MainTitle": "Contact me",
        "subtitle": "Fill out the form below to contact me"
      },
      "Resume": {
        "data": {
          "id": 74,
          "attributes": {
            "name": "CV_20_07_2024.pdf",
            "alternativeText": null,
            "caption": null,
            "width": null,
            "height": null,
            "formats": null,
            "hash": "CV_20_07_2024_eca5ea5262",
            "ext": ".pdf",
            "mime": "application/pdf",
            "size": 110.35,
            "url": `./uploads/Resume_v${versionResume}.pdf`,
            "previewUrl": null,
            "provider": "local",
            "provider_metadata": null,
            "createdAt": "2024-07-20T13:20:15.023Z",
            "updatedAt": "2024-07-20T13:20:15.023Z"
          }
        }
      },
      "pictureResume": {
        "data": {
          "id": 71,
          "attributes": {
            "name": "Resume_JavaScript_dev_Giovanni_Zoppis.png",
            "alternativeText": "Resume JavaScript dev Giovanni Zoppis",
            "caption": null,
            "width": 1414,
            "height": 2000,
            "formats": {
              "thumbnail": {
                "name": "thumbnail_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "thumbnail_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 110,
                "height": 156,
                "size": 18.58,
                "sizeInBytes": 18583,
                "url": "/uploads/thumbnail_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              },
              "medium": {
                "name": "medium_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "medium_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 530,
                "height": 750,
                "size": 200.58,
                "sizeInBytes": 200576,
                "url": "/uploads/medium_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              },
              "large": {
                "name": "large_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "large_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 707,
                "height": 1000,
                "size": 284.4,
                "sizeInBytes": 284397,
                "url": "/uploads/large_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              },
              "small": {
                "name": "small_Resume_JavaScript_dev_Giovanni_Zoppis.png",
                "hash": "small_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
                "ext": ".png",
                "mime": "image/png",
                "path": null,
                "width": 354,
                "height": 500,
                "size": 110.28,
                "sizeInBytes": 110281,
                "url": "/uploads/small_Resume_Java_Script_dev_Giovanni_Zoppis_3087438417.png"
              }
            },
            "hash": "Resume_Java_Script_dev_Giovanni_Zoppis_3087438417",
            "ext": ".png",
            "mime": "image/png",
            "size": 123.36,
            "url": `./uploads/Resume_v${versionResume}.png`,
            "previewUrl": null,
            "provider": "local",
            "provider_metadata": null,
            "createdAt": "2024-05-21T15:04:33.773Z",
            "updatedAt": "2024-07-20T13:21:58.925Z"
          }
        }
      },
      "messageCtaText": {
        "id": 3,
        "text": "Send"
      },
      "resumeCtaText": {
        "id": 4,
        "text": "Download CV"
      },
      "labelMail": {
        "id": 5,
        "text": "Your email"
      },
      "labelSubject": {
        "id": 6,
        "text": "Subject"
      },
      "labelMessage": {
        "id": 7,
        "text": "Message"
      },
      "messageSent": {
        "id": 8,
        "text": "Message sent!"
      },
      "messageError": {
        "id": 9,
        "text": "An error has occurred. Please try again later."
      },
      "requiredMail": {
        "id": 10,
        "text": "The email is required."
      },
      "requiredSubject": {
        "id": 11,
        "text": "The subject is required."
      },
      "requiredMessage": {
        "id": 12,
        "text": "The message is required."
      },
      "invalidMail": {
        "id": 13,
        "text": "The email is invalid."
      },
      "messageErrorSubmit": {
        "id": 14,
        "text": "An error has occurred. Please try again later."
      },
    }
  },
  "meta": {}
}

export default contactEN