import Title from '../../components/Title/index.jsx';
import Footer from '../../components/Footer/index.jsx';
import './index.scss';
import CanvasThree from '../../components/CanvasThree.jsx';
import { memo } from 'react';
const NotFound = () => {
    const title = { MainTitle: "404 Page Not Found", subtitle: "The page you are looking for does not exist." };

    return (
        <>
            <CanvasThree
                location={'/404'}
                loading={false}
                error={null}
            />
            <main className='about-container'>
                {title &&
                    <Title datas={title} />}
            </main >
            <Footer />
        </>
    );
}

export default memo(NotFound);

