const legalMentions = {
  title: "Legal Notices",
  sections: [
    {
      title: "1. Website Publisher",
      content: `
        The website ${process.env.REACT_APP_WEBSITE_URL || 'Website Name'} is published by:
        - Name: ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'}
        - Status: ${process.env.REACT_APP_COMPANY_STATUS || 'Micro-entrepreneur'}
        - Address: ${process.env.REACT_APP_COMPANY_ADDRESS || 'Your professional or personal address'}
        - SIRET: ${process.env.REACT_APP_COMPANY_SIRET || 'SIRET number'}
        - VAT number: ${process.env.REACT_APP_COMPANY_VAT || 'VAT number if applicable'}
        - Contact email: ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@yourwebsite.com'}
      `
    },
    {
      title: "2. Website Hosting",
      content: `
        The website is hosted by:
        - Hosting provider: ${process.env.REACT_APP_HOSTING_PROVIDER || 'Vercel Inc.'}
        - Address: ${process.env.REACT_APP_HOSTING_ADDRESS || '340 S Lemon Ave #4133, Walnut, CA 91789'}
        - Website: ${process.env.REACT_APP_HOSTING_WEBSITE || 'https://vercel.com'}
        - Contact: ${process.env.REACT_APP_HOSTING_CONTACT || 'support@vercel.com'}
      `
    },
    {
      title: "3. Intellectual Property",
      content: `
        All elements present on the website, including text, images, logos, and other content, are protected by intellectual property laws. 
        Any reproduction, distribution, modification, or exploitation, whether in whole or in part, of the elements of the website without the express and prior authorization of ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'}'} is prohibited.
      `
    },
    {
      title: "4. Collection and Processing of Personal Data",
      content: `
        The information collected through the contact form (email, subject, and message) is intended solely for communication between the user and ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'}.
        This data is neither shared nor sold to third parties.
        - Data controller: ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'}
        - Contact email: ${process.env.REACT_APP_CONTACT_EMAIL || 'contact@yourwebsite.com'}
        In accordance with the General Data Protection Regulation (GDPR), you have the right to access, correct, and delete your personal data.
      `
    },
    {
      title: "5. Cookies",
      content: `
        The website does not use cookies to collect personal data.
      `
    },
    {
      title: "6. Liability",
      content: `
        ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'} strives to ensure the accuracy of the information on the website, but cannot be held responsible for any errors or omissions, or for the results obtained from the use of this information.
      `
    },
    {
      title: "7. Hyperlinks",
      content: `
        The website may contain hyperlinks to other websites. ${process.env.REACT_APP_COMPANY_NAME || 'Company Name'} assumes no responsibility for the content of these sites or any damages that may result from visiting them.
      `
    },
    {
      title: "8. Changes to Legal Notices",
      content: `
        These legal notices may be modified at any time, without prior notice, to adapt them to changes to the website or regulations. We invite users to review them regularly.
      `
    }
  ]
};

export default legalMentions;