import { memo, useRef } from 'react';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { iconMapping } from '../../utils/icons';
import useHandleScroll from '../../hooks/useHandleScroll';
import './index.scss'
import { useDatasContext } from '../../context/DatasProvider';

const CardProject = ({ datas }) => {
    const cardRef = useRef();
    const { locale } = useDatasContext();
    const urlImageDatas = datas?.attributes?.imageproject?.data?.attributes;
    const urlImage = `${urlImageDatas.url}`
    const altImage = `${urlImageDatas.alternativeText}`
    const tags = datas.attributes.tags.map((el, index) => {
        return el
    });
    const projectTitle = datas.attributes.title ?? undefined;
    const projectdescription = datas.attributes.description ?? undefined;
    useHandleScroll(cardRef);
    const linksProjects = datas.attributes.link;
    // const linksProjects = [{
    //     icon: "github",
    //     url: " https://www.github.com",
    // },
    // {
    //     icon: "website",
    //     url: " https://www.linkedin.com/in/giovanni-zoppis/",
    // },
    // {
    //     icon: "demo",
    //     url: " https://www.linkedin.com/in/giovanni-zoppis/",
    // },
    // ];
    const textLinks = (text) => {
        switch (text) {
            case "github":
                return locale ? "Code source" : "Source code"
            case "website":
                return locale ? "Site internet" : "Website"
            case "demo":
                return locale ? "Démonstration" : "Demo"
            default:
                return "lien vers le projet"
        }
    }

    if (!urlImageDatas || !tags || !projectTitle) return null

    return (
        <div className={"projects-card"} ref={cardRef}>
            <div className='img-container' >
                <img
                    src={urlImage}
                    alt={altImage}
                />
            </div>
            <div className='project-tags-container' >
                {tags.map((el, index) => <p key={index} className='project-tag' >{el}</p>)}
            </div>
            <h2 className="project-title" >{projectTitle}</h2>
            <div className="project-description">
                <article className='desc-container'>
                    <BlocksRenderer
                        blocks={{
                            link: ({ children, url }) => <a href={url} target="__blank" rel="noreferrer">{children}</a>,
                        }}
                        content={projectdescription} />
                </article>
                {linksProjects.length > 0 && <section className='links-project' >
                    {linksProjects.map((link, index) => {
                        const IconComponent = iconMapping[link.icon].icon;
                        const text = textLinks(link.icon);
                        return (
                            <div className='link-container' key={index} >
                                <a
                                    key={link.id}
                                    href={link.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className='link'
                                ><span className='link-icon'>{IconComponent && <IconComponent />}</span> {text}
                                </a>
                            </div>)
                    })}
                </section>
                }
            </div>
        </div>
    );
};

export default memo(CardProject);